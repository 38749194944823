<i18n src="./i18n.json"></i18n>

<template>
    <div class="row">
        <div class="onerow card ">
            <div class=" card-header " style="text-align: center">
                <h3>{{$t('Retreat')}}</h3>
                <h5>{{$t('Payment success')}}</h5>
            </div>
           <div class="card-body">
               {{$t('Provide registrator your UID')}}
           </div>
            <div class="card-footer">
                <router-link class="btn btn-primary" to="/express">{{$t('Close')}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'approved'
  }
</script>

<style lang="scss" scoped>

</style>
