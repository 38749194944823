<template>
    <b-form @submit.prevent="tryLogin">
        <div class="container" >
            <div class="row" style="padding-top: 20%">
                <div class="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-10 offset-sm-1 border"
                     style="padding: 10px">
                    <b-alert
                            variant="danger"
                             dismissible
                             :show="error.length>0"
                             @dismissed="clearError">{{error}}
                    </b-alert>

                    <b-form-group label="Логин" label-for="login">
                        <b-form-input
                                id="login"
                                type="text"
                                ref="login"
                                v-model="login"
                                required
                                placeholder="Login"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Пароль" label-for="password">
                        <b-form-input
                                id="password"
                                type="password"
                                v-model="secret" required
                                placeholder="Пароль"
                                ref="secret"
                        ></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Вход</b-button>

                </div>

            </div>
        </div>
    </b-form>

</template>

<script>
  export default {
    name: 'Login',
    props: {},
    data: function() {
      return {
        login: '',
        secret: '',
        error: ''
      }
    },
    methods: {
      async tryLogin() {
        this.clearError();
        const postData = {
          admin_name: this.login,
          admin_secret: this.secret
        };
        try {
          this.$loading(true);
          console.log(process.env);

          const result = await this.$http.post(`${process.env.VUE_APP_API}/login`, postData);
          this.$loading(false);
          const { body } = result;
          if ('token' in body) {
            this.$session.start();
            for (let key in body) {
              this.$session.set(key, body[key]);
            }
            this.token = body.token;
            this.$router.push('/');
            return;
          }
          this.error = body;
        } catch (e) {
          this.$loading(false);
          switch (e.status) {
            case 401:
              this.error = 'Ошибка авторизации';
              break;
            default:
              console.error(e);
              this.error = 'Ошибка сервера';
          }
        }
      },
      clearError() {
        this.error = '';
        this.$refs.secret.focus();
      }
    },
    mounted() {
      if (this.$session.exists()) {
        this.$router.push('/');
        return;
      }
      this.$refs.login.focus();
    }
  }
</script>
