<i18n src="./i18n.json"></i18n>

<template>
    <div class="row">
        <div class="onerow card ">
            <div class=" card-header " style="text-align: center">
                <h3>{{$t('Retreat')}}</h3>
            </div>
           <div class="card-body" style="color: red">
               {{$t('Payment refused')}}
           </div>
            <div class="card-footer">
                <router-link class="btn btn-primary" to="express">{{$t('Close')}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'declined'
  }
</script>

<style lang="scss" scoped>

</style>
